
import { defineComponent, ref, onMounted } from "vue";
import sedes from "@/services/sedes.service";

export default defineComponent({
  name: "LocationsHome",

  setup() {
    const listSedes = ref([]);
    const traerSedes = () => {
      sedes.listarSedes().then((data) => {
        listSedes.value = data.data;
      });
    };
    onMounted(() => {
      traerSedes();
    });
    return {
      traerSedes,
      listSedes,
    };
  },
});
