
import { defineComponent, onMounted, ref } from "vue";
import ListLocations from "@/components/widgets/lists/Locations.vue";
import ReportsToRead from "@/components/widgets/cards/ReportsToRead.vue";
import equipmentAlert from "@/components/widgets/lists/EquipmentAlert.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {
    ListLocations,
    ReportsToRead,
    equipmentAlert,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const mostrar = ref(false);
    const despliegueInformes = () => {
      mostrar.value = !mostrar.value;
    };
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      setCurrentPageBreadcrumbs("Inicio", ["Pages", "Dashboard"]);
    });

    const menu = [
      { name: "Perfil", icon: "person-circle", ruta: "profile-overview" },
      { name: "Mis equipos", icon: "hdd", ruta: "profile-equipos" },
      { name: "Bitácora", icon: "file-richtext", ruta: "profile-campaigns" },
      {
        name: "Informes",
        icon: "clipboard-data",
        ruta: "profile-informes-mosaico",
      },
      { name: "Mi grupo", icon: "people-fill", ruta: "profile-mi-grupo" },
    ];
    const equipo = { nombre: "Equipo 1", localizacion: "Salón 1" };

    return {
      menu,
      equipo,
      signOut,
      despliegueInformes,
      mostrar,
    };
  },
});
